/**
 * 발송된 인증번호와 입력된 인증번호 확인
 * @param certifyCode CompareCertifyCodeQueryDTO
 * @returns Promise<CheckPhoneAuthCodeResponseDTO>
 */ export class CompareCertifyCodeCommand {
    execute(param) {
        let { phone , certify_code  } = param;
        const url = "/accounts/compare-certify-code";
        const params = new URLSearchParams();
        params.set("phone", phone.toString());
        params.set("certify_code", certify_code.toString());
        return this.apiService.fetch(url, {
            method: "GET",
            params
        }).then((res)=>res.data);
    }
    constructor(apiService){
        this.apiService = apiService;
    }
}
