import { api } from ".";
export const signUpPhone = async (param)=>{
    let { phone , name , birth , password , password_confirm , email , options , referral  } = param;
    const { data  } = await api.post("/accounts/signup/phone", {
        phone,
        name,
        birth,
        password,
        password_confirm,
        email,
        options,
        referral
    });
    return data;
};
