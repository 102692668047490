import { api } from "../../../api";
/**
 * 비밀번호 변경을 위한 휴대폰 인증번호 발송
 * @param dto SendCertifySmsRequestDTO
 * @returns Promise<SendCertifySmsResponseDTO>
 */ export class SendCertifySmsCommand {
    execute(dto) {
        const url = "/accounts/send-certify-sms";
        return api(url, {
            method: "POST",
            data: dto
        }).then((res)=>res.data);
    }
    constructor(apiService){
        this.apiService = apiService;
    }
}
