export class ResetPasswordCommand {
    execute(dto) {
        const url = "/accounts/reset-password";
        return this.apiService.fetch(url, {
            data: dto,
            method: "put"
        }).then((res)=>res.data);
    }
    constructor(apiService){
        this.apiService = apiService;
    }
}
