import { useLocalObservable } from "mobx-react-lite";
import { useMemo } from "react";
import { SendCertifySmsCommand } from "../../commands/sendCertifySmsCommand";
import { CompareCertifyCodeCommand } from "../../commands/compareCertifyCodeCommand";
import { ChangePasswordCommand } from "../../commands/changePasswordCommand";
import { Modal } from "antd";
import { LOGIN_EXIST_KAKAO, LOGIN_WITH_PHONE_PAGE } from "@/settings/constant";
import { ResetPasswordCommand } from "../../commands/resetPasswordCommand";
export const ChangePasswordFormViewModel = (param)=>{
    let { router , apiService , authStore  } = param;
    const store = useLocalObservable(()=>({}));
    const sendCertifySmsCommand = useMemo(()=>new SendCertifySmsCommand(apiService), [
        apiService
    ]);
    const compareCertifyCodeCommand = useMemo(()=>new CompareCertifyCodeCommand(apiService), [
        apiService
    ]);
    const changePasswordCommand = useMemo(()=>new ChangePasswordCommand(apiService), [
        apiService
    ]);
    const resetPasswordCommand = useMemo(()=>new ResetPasswordCommand(apiService), [
        apiService
    ]);
    const status = useLocalObservable(()=>{
        var _authStore_user;
        return {
            errors: [],
            initialized: false,
            loading: false,
            passwordError: [
                false,
                false,
                false
            ],
            passwordValidation: true,
            timer: null,
            requestId: 1,
            initialStatus: {
                apiErrors: {}
            },
            initialValues: {
                phone: authStore.isLoggedIn ? (_authStore_user = authStore.user) === null || _authStore_user === void 0 ? void 0 : _authStore_user.phoneNumber : "",
                certification_code: "",
                password: "",
                password_confirmation: ""
            },
            sendCertificationCode: false,
            certified: false,
            remainingTime: 300000,
            isPaused: false
        };
    });
    const actions = useLocalObservable(()=>({
            async onSendCertificationCode (value) {
                try {
                    const response = await sendCertifySmsCommand.execute({
                        phone: value
                    });
                    ;
                    if (response.success) {
                        const startTimer = function() {
                            status.isPaused = false;
                            status.timer = setInterval(function() {
                                status.remainingTime -= 1000;
                                if (status.remainingTime <= 0) {
                                    clearInterval(status.timer);
                                }
                            }, 1000);
                        };
                        const stopTimer = function() {
                            clearInterval(status.timer);
                            status.remainingTime = 300000;
                            status.isPaused = true;
                        };
                        if (!status.timer) {
                            startTimer();
                        } else {
                            stopTimer();
                            startTimer();
                        }
                        status.sendCertificationCode = true;
                    } else {
                        Modal.error({
                            title: "인증번호 발송 실패",
                            content: "다시 한 번 시도해주세요."
                        });
                    }
                } catch (error) {
                    ;
                    if (error.response.data.message === "카카오 계정으로 가입한 회원은 비밀번호 변경을 진행할 수 없습니다.") {
                        router.push(LOGIN_EXIST_KAKAO);
                    } else {
                        Modal.error({
                            title: "인증번호 발송 실패",
                            content: "다시 한 번 시도해주세요."
                        });
                    }
                }
            },
            onCountTimer () {
                const timer = setInterval(()=>{
                    status.remainingTime -= 1000;
                    if (status.remainingTime <= 0) {
                        clearInterval(timer);
                    }
                }, 1000);
            },
            onResetTimer () {
                status.remainingTime = 300000;
            },
            async onCheckCertificationCode (phone, certify_code) {
                try {
                    const response = await compareCertifyCodeCommand.execute({
                        phone,
                        certify_code
                    });
                    ;
                    if (response.success) {
                        status.certified = true;
                        status.remainingTime = 0;
                        return;
                    }
                // console.log(status.certified)
                } catch (error) {
                    ;
                // 모달이 아니라 Input 밑에 에러 메세지 띄우기
                }
            },
            passwordValidator (value) {
                const regSpace = /\s/g;
                if (!value.match(/(\w)\1\1/) && !value.match(/([~!@#$%^&*()_+=])\1\1/)) {
                    status.passwordError[0] = true;
                } else status.passwordError[0] = false;
                ;
                if (value.match(/^(?!([a-zA-Z]+|[0-9]+|[~!@#$%^&*()_+=]+)$)[a-zA-Z\d~!@#$%^&*()_+=]/) && !regSpace.test(value)) {
                    status.passwordError[1] = true;
                } else status.passwordError[1] = false;
                if (value.length >= 10) {
                    status.passwordError[2] = true;
                } else status.passwordError[2] = false;
                status.passwordValidation = status.passwordError.filter((status)=>status === false).length !== 0;
            },
            async onSubmit (values, formikActions) {
                formikActions.setSubmitting(true);
                try {
                    const response = await resetPasswordCommand.execute({
                        phone: values.phone,
                        password: values.password,
                        password_confirm: values.password_confirmation
                    });
                    ;
                    if (response.success) {
                        Modal.success({
                            title: "비밀번호 변경 성공"
                        });
                        formikActions.resetForm();
                        router.push(LOGIN_WITH_PHONE_PAGE);
                    } else {
                        ;
                        Modal.error({
                            title: "",
                            content: "오류가 발생한 것 같습니다. 새로고침 후 다시 시도해주세요."
                        });
                    }
                } catch (error) {
                    ;
                    Modal.error({
                        title: "",
                        content: "오류가 발생한 것 같습니다. 새로고침 후 다시 시도해주세요."
                    });
                } finally{
                    formikActions.setSubmitting(false);
                }
            },
            dispose () {}
        }));
    return {
        status,
        actions
    };
};
