import { otherApiClient } from ".";
export const getSignUpBenefit = async ()=>{
    const { data  } = await otherApiClient.market.get("/signUp/guest");
    return data;
};
export const getMemberSignUpCheck = async (params)=>{
    const { data  } = await otherApiClient.market.get("/signUp/lookUp", {
        params
    });
    return data;
};
export const getSchools = async (params)=>{
    const { data  } = await otherApiClient.market("/signup/schools", {
        params
    });
    return data;
};
export const getSignUpCompleted = async ()=>{
    const { data  } = await otherApiClient.market("/signup/completed");
    return data;
};
