import { api } from "../../../api";
/**
 * 휴대폰번호로 로그인
 * @param dto LoginUserWithPhoneDTO
 * @returns Promise<LoginUserWithPhoneResponseDTO>
 */ export class LoginPhoneCommand {
    execute(dto) {
        const url = "/sessions/phone";
        return api(url, {
            method: "POST",
            data: dto
        }).then((res)=>res.data);
    }
    constructor(apiService){
        this.apiService = apiService;
    }
}
