/**
 * 비밀번호 변경
 * @param query ChangePasswordQueryDTO
 * @param dto ChangePasswordRequestDTO
 * @returns Promise<ChangePasswordResponseDTO>
 */ export class ChangePasswordCommand {
    execute(query, dto) {
        const url = "/users/".concat(query.id, "/password");
        return this.apiService.fetch(url, {
            data: dto,
            method: "put"
        }).then((res)=>res.data);
    }
    constructor(apiService){
        this.apiService = apiService;
    }
}
